<script>
import { mapState } from 'vuex'
import L from 'leaflet'
import '../styles/leaflet.css'
import 'leaflet-rotatedmarker'
import '../api/L.KML.js'
export default {
  data() {
    return {
      iconInfo: [],
      machineId: null,
      zoomNum: 20,
      map: null,
      markers: [],
      sideLatLngInfo: {},
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState(['machineInfo', 'latLngInfo', 'isLogin', 'stationInfoList']),
  },
  watch: {
    zoomNum() {
      console.log('machineInfo', this.machineInfo)
    },
    machineId() {
      const infoBlockDom = document.getElementById('info-block')
      let machineId = infoBlockDom.getAttribute('data-machineId')
      let machineType = this.getRouteName(
        infoBlockDom.getAttribute('data-machineType')
      )
      const popupCustom = document.getElementsByClassName('popup-custom')
      let machineGroup = infoBlockDom.getAttribute('data-machineGroup')
      let machineName = infoBlockDom.getAttribute('data-machineName')
      popupCustom[0].addEventListener(
        'click',
        (e) => {
          console.log(
            'sin',
            this.machineInfo.filter(
              (e) =>
                e.machine_group === machineGroup &&
                e.machine_name.includes('攝影機')
            )
          )
          const machineByGroup = this.machineInfo.filter(
            (e) => e.machine_group === machineGroup
          )
          const indexInGroup = machineByGroup.findIndex(
            (e) => e.id === parseInt(machineId)
          )
          console.log('sin indexInGroup', indexInGroup)
          const parentElement = e.target.parentElement
          if (parentElement.classList.contains('leaflet-popup-close-button'))
            return
          if (machineGroup === '北出土') {
            machineGroup = 'nt'
          }
          console.log(
            'machineGroup',
            machineGroup,
            'machineType',
            machineType,
            machineType.indexOf('Env'),
            'machineId',
            machineId
          )
          if (machineType.indexOf('Env') !== -1) {
            console.log('router push Env')
            this.$router.push({
              name: machineType,
              params: {
                key: machineGroup.toLowerCase(),
                id: machineId,
              },
            })
          } else if (machineType.indexOf('Cctv') !== -1) {
            const cctvId = machineName.substring(
              machineName.indexOf('攝影機_') + 4,
              machineName.indexOf('攝影機_') + 5
            )
            if (machineGroup.toLowerCase() === 'g07_1') {
              machineGroup = 'g07'
            }
            console.log('router push Cctv', cctvId)
            this.$router.push({
              name: machineType,
              params: {
                key: machineGroup.toLowerCase(),
                id: cctvId,
              },
            })
          } else {
            console.log('router push 2')
            this.$router.push({
              name: machineType,
              params: {
                key: machineGroup.toLowerCase(),
              },
            })
          }
        },
        true
      )
    },
  },
  methods: {
    resetIcon() {
      for (let i = 0; i < this.markers.length; i++) {
        this.map.removeLayer(this.markers[i])
      }
      this.setIcon()
    },
    setIcon() {
      let scale = 1
      if (this.map.getZoom() > 16) {
        if (this.map.getZoom() < 20) {
          scale = this.map.getZoom() / 25
        }
        this.machineInfo.forEach((machine) => {
          const iconUrl =
            this.windowWidth < 768
              ? machine.machine_type
              : machine.machine_type + '-pc'
          const tempIcon = L.icon({
            iconUrl: '/' + iconUrl + '.png',
            iconSize: [35 * scale, 35 * scale], // size of the icon
            className: machine.machine_type + '-' + machine.id,
          })
          const customOptions = {
            maxWidth: '400',
            width: '200',
            className: 'popup-custom',
          }
          const popupContent =
            '<div class="info-block" id="info-block" data-machineId=' +
            machine.id +
            ' data-machineType=' +
            machine.machine_type +
            ' data-machineGroup=' +
            machine.machine_group +
            ' data-machineName=' +
            machine.machine_name +
            '><p>巡查區域： ' +
            machine.machine_group +
            '  </p><p>巡查點： ' +
            machine.machine_name +
            '</div>'
          let m = L.marker([machine.latitude, machine.longitude], {
            icon: tempIcon,
          })
            .on('click', this.onClick.bind(null, machine, machine))
            .bindPopup(popupContent, customOptions)
          this.markers.push(m)
          this.map.addLayer(m)
        })
      } else if (this.map.getZoom() <= 16) {
        var groupIcon = Object.groupBy(
          this.machineInfo,
          ({ machine_group }) => machine_group
        )

        let chunkArr = []
        Object.keys(groupIcon).forEach((group) => {
          chunkArr.push({
            longitude: this.medInArr(groupIcon[group].map((e) => e.longitude)),
            latitude: this.medInArr(groupIcon[group].map((e) => e.latitude)),
            length: groupIcon[group].length,
          })
        })
        chunkArr.forEach((chunk) => {
          const tempIcon = L.divIcon({
            html:
              `<div class="icon-div" data-lng=` +
              chunk.longitude +
              ` data-lat=` +
              chunk.latitude +
              `><span>` +
              chunk.length +
              `</span></div>`,
          })
          let m = L.marker([chunk.latitude, chunk.longitude], {
            icon: tempIcon,
          }).on('click', this.setCenter.bind(null, chunk, chunk))
          this.markers.push(m)
          this.map.addLayer(m)
        })
        var userSelection = document.getElementsByClassName('icon-div')

        for (var i = 0; i < userSelection.length; i++) {
          userSelection[i].addEventListener('click', () => {
            console.log(
              'Clicked index: ' + userSelection[i].getAttribute('data-lng')
            )
            this.map.setView(
              [
                userSelection[i].getAttribute('data-lat'),
                userSelection[i].getAttribute('data-lng'),
              ],
              18
            )
          })
        }
      }
    },
    medInArr(arr) {
      let concat = arr
      let med = 0
      concat = concat.sort((a, b) => a - b)
      const length = concat.length
      if (length % 2 === 1) {
        med = concat[length / 2 - 0.5]
      } else {
        med = (concat[length / 2] + concat[length / 2 - 1]) / 2
      }
      return med
    },
    getRouteName(machineType) {
      let routeName = ''
      switch (machineType) {
        case 'camera':
          routeName = 'Cctv'
          break
        case 'cmaera':
          routeName = 'Cctv'
          break
        case 'face':
          routeName = 'People'
          break
        case 'air_4in1':
          routeName = 'Env'
          break
        case 'air_PM2.5':
          routeName = 'EnvPM'
          break
        case 'dashboard':
          routeName = 'Dashboard'
          break
      }
      return routeName
    },
    onClick(event, machine, machine2) {
      console.log('onClick', machine.machine_type, machine2)
      this.machineId = machine.id
    },
    setCenter(event, chunk, chunk2) {
      console.log('setCenter', chunk, chunk2)
      this.map.setView([chunk.latitude, chunk.longitude], 18)
    },
    goStation(key) {
      if (!this.isLogin) return
      if (key === this.$route.params.key) return
      console.log(' goStation key', key)
      this.$router.push({ name: 'Map', params: { key: key } })
    },
    goCctvList() {
      this.$router.push({
        name: 'CctvList',
        params: { key: this.$route.params.key },
      })
    },
  },
  created() {
    const key = Object.keys(this.latLngInfo).find(
      (side) => this.latLngInfo[side].key === this.$route.params.key
    )
    this.sideLatLngInfo = this.latLngInfo[key]
  },
  mounted() {
    this.map = L.map('map').setView(
      [this.sideLatLngInfo.lat, this.sideLatLngInfo.lng],
      18
    )
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 30,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map)
    this.setIcon()
    this.map.on('zoomend', () => {
      console.log('zoomend', this.map.getZoom(), this.zoomNum)
      this.resetIcon()
    })
    // fetch('/G07-2.kml')
    //   .then((res) => res.text())
    //   .then((kmltext) => {
    //     const parser = new DOMParser()
    //     const kml = parser.parseFromString(kmltext, 'text/xml')
    //     const track = new L.KML(kml)
    //     this.map.addLayer(track)
    //     const bounds = track.getBounds()
    //     this.map.fitBounds(bounds)
    //   })
  },
}
</script>

<template>
  <div id="map-view">
    <div id="map"></div>
    <div class="map-list">
      <button class="btn-cctv" @click="goCctvList">
        <img src="../assets/camera.png" /><span>CCTV 整合</span>
      </button>

      <img
        @click="goStation(stationInfo.key)"
        class="preview-dot"
        :src="require('../assets/' + stationInfo.key + '-dot.png')"
        v-for="stationInfo in stationInfoList"
        :key="stationInfo.key"
        :class="{ active: stationInfo.key === $route.params.key }"
      />
    </div>
    <div id="icon-list">
      <div class="icon-type">
        <img v-if="windowWidth < 768" src="../assets/cmaera.png" />
        <img v-else src="../assets/cmaera-pc.png" />
        <span class="cn-name">攝影機</span>
      </div>
      <div class="icon-type">
        <img v-if="windowWidth < 768" src="../assets/face.png" />
        <img v-else src="../assets/face-pc.png" />
        <span class="cn-name">人臉辨識</span>
      </div>
      <div class="icon-type">
        <img v-if="windowWidth < 768" src="../assets/air_4in1.png" />
        <img v-else src="../assets/air_4in1-pc.png" />
        <span class="cn-name">氣體偵測</span>
      </div>
      <div class="icon-type">
        <img v-if="windowWidth < 768" src="../assets/air_PM2.5.png" />
        <img v-else src="../assets/air_PM2.5-pc.png" />
        <span class="cn-name">氣體偵測PM<span class="small">2.5</span></span>
      </div>
      <div class="icon-type">
        <img v-if="windowWidth < 768" src="../assets/dashboard.png" />
        <img v-else src="../assets/dashboard-pc.png" />
        <span class="cn-name">電子佈告欄</span>
      </div>
    </div>
  </div>
</template>
